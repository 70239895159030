import React, { useState, useEffect, useContext } from 'react';
import ReactPlayer from 'react-player';
import style from './Courses.module.css';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Certificate from '../../Assets/images/شهادة-اتمام-الدورة.png';
import Levels from '../../Assets/images/ثلاث-مستويات.png';
import Students from '../../Assets/images/عدد-طلاب.png';
import Clock from '../../Assets/images/ساعة.png';
import Lectuers from '../../Assets/images/عدد-المحاضرات.png';
import Lock from '../../Assets/images/قفل.png';
import Select from 'react-select';
import { toast, Toaster } from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';

const Courses = () => {
  const [videoTitle, setVideoTitle] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [videoOptions, setVideoOptions] = useState([]);
  const [SectionSlugs, setSectionSlugs] = useState('');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentVideo, setCurrentVideo] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [expandedSection, setExpandedSection] = useState(null);
  const [courseSlug, setCourseSlug] = useState('');
  const [sectionSlug, setSectionSlug] = useState(() => localStorage.getItem('selectedSectionSlug') || '');
  const [selectedLectureSlug, setSelectedLectureSlug] = useState(() => localStorage.getItem('selectedLectureSlug') || '');
  const [showModal, setShowModal] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  const isWebmSupported = document.createElement('video').canPlayType('video/webm; codecs="vp8, vorbis"');
  const { baseUrl } = useContext(BaseUrlContext);
  const { slug } = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [errorrMessage, setErrorrMessage] = useState('');
  const [sectionDatum, setSectionDatum] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [cachedSections, setCachedSections] = useState({});

  const getCourseData = () => {
    return axios.get(`${baseUrl}api/course/${slug}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

  const getSectionData = async () => {
    if (cachedSections[SectionSlugs]) {
      setSectionDatum(cachedSections[SectionSlugs]);
      return;
    }
  
    setLoading(true); 
    try {
      const response = await axios.get(`${baseUrl}api/course/${slug}/section/${SectionSlugs}/lectures`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSectionDatum(response.data); 
      
      setCachedSections(prev => {
        const updatedSections = { ...prev, [SectionSlugs]: response.data };
        localStorage.setItem('cachedSections', JSON.stringify(updatedSections));
        return updatedSections;
      });
    } catch (error) {
      console.error('Error fetching section data:', error);
    } finally {
      setLoading(false); 
    }
  };
  const getLectureData = (sectionSlug, lectureSlug) => {
    console.log('from server');
    
    return axios.get(`${baseUrl}api/course/${slug}/section/${sectionSlug}/lecture/${lectureSlug}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

  const { isLoading, isFetching, data } = useQuery('getCourseData', getCourseData, {
    refetchOnWindowFocus: false,
    staleTime: 24* 60 * 60 * 1000,
    cacheTime: 24* 60 * 60 * 1000,
  });

  const { data: lectureData, isLoading: isLectureLoading } =useQuery(['getLectureData', sectionSlug, selectedLectureSlug], () => getLectureData(sectionSlug, selectedLectureSlug), {
    enabled: !!sectionSlug && !!selectedLectureSlug,
    staleTime: 24* 60 * 60 * 1000,  
    cacheTime: 24* 60 * 60 * 1000,  
  });

  // useEffect(() => {
  //   const cachedData = localStorage.getItem('cachedSections');
  //   if (cachedData) {
  //     console.log("Cached Sections:", JSON.parse(cachedData));
  //   }
  // }, []);

  useEffect(() => {
  const savedSections = localStorage.getItem('cachedSections');
  if (savedSections) {
    setCachedSections(JSON.parse(savedSections));
  }
}, []);

useEffect(() => {
  const savedVideo = localStorage.getItem('currentVideo');
  const savedVideoOptions = localStorage.getItem('videoOptions');
  const savedVideoTitle = localStorage.getItem('videoTitle');
  // console.log("Loaded from localStorage:", { savedVideo, savedVideoOptions, savedVideoTitle });

  if (savedVideo) {
    setCurrentVideo(savedVideo);
  }
  if (savedVideoOptions) {
    setVideoOptions(JSON.parse(savedVideoOptions));
  }
  if (savedVideoTitle) {
    setVideoTitle(savedVideoTitle);
  }
}, []);

  useEffect(() => {
    if (data && data.data && data.data.data) {
      const courseData = data.data.data;
      setCourseId(courseData.slug);

      if (courseData.slug && courseData.sections && courseData.sections.length > 0) {
        const courseSlug = courseData.slug;
        const initialSectionSlug = localStorage.getItem('selectedSectionSlug') || courseData.sections[0].slug;

        setCourseSlug(courseSlug);
        setSectionSlug(initialSectionSlug);
      }
    }
  }, [data]);

  useEffect(() => {
    // Fetch section data whenever SectionSlugs changes
    if (SectionSlugs) {
      getSectionData();
    }
  }, [SectionSlugs]);

  useEffect(() => {
    if (selectedLectureSlug && lectureData) {
      const videos = lectureData?.data?.data?.converted_videos || [];
      findSupportedVideo(videos);
      setAttachments(lectureData?.data?.data?.attachments || []);
    }
  }, [sectionSlug, selectedLectureSlug, lectureData]);

  const handleSectionClick = (newSectionSlug) => {
    setSectionSlugs(newSectionSlug);
    setSectionSlug(newSectionSlug);
    localStorage.setItem('selectedSectionSlug', newSectionSlug);
    setExpandedSection(null);
  };

  const findSupportedVideo = (videos) => {
    const webmVideos = videos.filter(video => video.endsWith('.webm'));
    const mp4Videos = videos.filter(video => video.endsWith('.mp4'));
  
    let defaultVideo;
    let videoOptions = [];
  
    if (webmVideos.length > 0) {
      defaultVideo = webmVideos[0];
      videoOptions = webmVideos.map((video) => {
        const quality = video.split('/').pop().split('-').pop().replace('.webm', '');
        return {
          value: video,
          label: quality
        };
      });
    } else if (mp4Videos.length > 0) {
      defaultVideo = mp4Videos[0];
      videoOptions = [{ value: null, label: "No options" }];
    } else {
      defaultVideo = null;
      videoOptions = [];
    }
  
    setCurrentVideo(defaultVideo);
    setVideoOptions(videoOptions);
  
    localStorage.setItem('currentVideo', defaultVideo);
    localStorage.setItem('videoOptions', JSON.stringify(videoOptions));
  };
  const handleVideoClick = (index, newSectionSlug, newLectureSlug, title) => {
    setActiveIndex(index);
    setSectionSlug(newSectionSlug);
    setSelectedLectureSlug(newLectureSlug);
    setVideoTitle(title);
  
    localStorage.setItem('selectedLectureSlug', newLectureSlug);
    localStorage.setItem('videoTitle', title);
  };
  
  const handleVideoQualityChange = (selectedOption) => {
    setCurrentVideo(selectedOption.value);
    localStorage.setItem('currentVideo', selectedOption.value);
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  const handleAttachmentsModalToggle = () => {
    setShowAttachmentsModal(!showAttachmentsModal);
  };

  function convertToArabicNumbers(text) {
    const arabicNumerals = '٠١٢٣٤٥٦٧٨٩';
    return text?.replace(/\d/g, (match) => arabicNumerals[parseInt(match)]);
  }

  return (
    <>
      {errorMessage && (
        <div style={{
          position: 'absolute',
          top: '20px', 
          left: '50%',
          transform: 'translateX(-50%)', 
          backgroundColor: 'white',
          color: '#721c24',
          padding: '10px 20px', 
          border: '1px solid #f5c6cb',
          borderRadius: '5px',
          zIndex: '1000', 
        }}>
          {errorMessage}
        </div>
      )}

      <div className={`${style.course} p-2 p-sm-5`}>
        {/* Header Section */}
        <div className={`${style.header} my-3 my-sm-5 d-lg-flex d-md-block justify-content-between align-items-center`}>
          <div className={`${style.left}`}>
            <h1>{data?.data?.data?.title}</h1>
            <div className={`${style.specifications1} mt-4 d-flex justify-content-start align-items-center gap-2`}>
              <p>تقيمات ({convertToArabicNumbers(data?.data?.data?.rating.toString())}) </p>
              <img className={`${style.ig}`} src={Certificate} alt="شهادة اتمام الدورة" />
              <img className={`${style.ig}`} src={Levels} alt="ثلاث مستويات" />
            </div>
            <div className={`${style.specifications2} mb-3 mt-3 mt-sm-4 mb-sm-0 d-flex justify-content-start align-items-center gap-2`}>
              <div className="d-flex align-items-center gap-1">
                <img src={Students} alt="عدد طلاب" />
                <p>{convertToArabicNumbers(data?.data?.data?.students_count.toString())}</p>
              </div>
              <div className={`${style.middle} d-flex align-items-center gap-1 px-3 mx-1`}>
                <img src={Clock} alt="ساعة" />
                <p>{convertToArabicNumbers(data?.data?.data?.total_duration.toString())} ساعة</p>
              </div>
              <div className="d-flex align-items-center gap-1">
                <img src={Lectuers} alt="عدد المحاضرات" />
                <p>{convertToArabicNumbers(data?.data?.data?.lessons.toString())} درس</p>
              </div>
            </div>
          </div>
          <div className={`${style.right} mt-lg-0 mt-md-5`}>
            {!data?.data?.data?.is_enrolled && (
              <div className="w-100 d-flex gap-2 mb-2 mb-sm-0">
                <h2 className={`${style.offer}`}> {convertToArabicNumbers(data?.data?.data?.price.toString())} جنية</h2>
                <h2>{convertToArabicNumbers((data?.data?.data?.price - data?.data?.data?.discount_price).toString())} جنية</h2>
              </div>
            )}
            {!data?.data?.data?.is_enrolled && (
              <button className="w-100 btn" onClick={handleModalToggle}>سجل في الدورة</button>
            )}
          </div>
        </div>

        {/* Enrollment Modal */}
        {showModal && (
          <div className={style.modalOverlay} onClick={() => setShowModal(false)}>
            <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
              <h2>طلب التسجيل في الدورة</h2>
              {successMessage && <div className="alert alert-success">{successMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = {
                    course_slug: courseId,
                    phone: e.target.phone.value,
                    message: e.target.message.value,
                  };

                  const token = localStorage.getItem('token');
                  
                  const headers = token
                    ? {
                        'Authorization': `Bearer ${token}`,
                      }
                    : {};

                  axios.post(`${baseUrl}api/request-course`, formData, { headers })
                    .then(response => {
                      toast.success('تم تسجيل طلبك بنجاح');
                      setSuccessMessage('تم التسجيل بنجاح');
                      setErrorMessage('');  
                      setShowModal(false);  
                    })
                    .catch(error => {
                      if (error.response && error.response.data) {
                        const errorMessages = error.response.data.errors || error.response.data.message || 'حدث خطأ ما';
                        
                        if (typeof errorMessages === 'object') {
                          setErrorMessage(Object.values(errorMessages).join(' '));
                        } else {
                          setErrorMessage(errorMessages);
                        }
                        
                        toast.error('عذرا حاول مرة اخرى');
                      } else {
                        setErrorMessage('حدث خطأ ما. يرجى المحاولة لاحقًا.');
                      }
                      
                      console.error('خطأ في التسجيل:', error);
                      setShowModal(false); 
                    });
                }}
              >
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">رقم الهاتف</label>
                  <input type="text" id="phone" name="phone" className="form-control" placeholder="رقم الهاتف" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">رسالة</label>
                  <textarea id="message" name="message" className="form-control" rows="3" placeholder="اكتب رسالتك هنا" required></textarea>
                </div>
                <button type="submit" className={`${style.btt}`}>إرسال</button>
                <button type="button" className={`${style.btt1} bg-danger`} onClick={() => setShowModal(false)}>إغلاق</button>
              </form>
            </div>
          </div>
        )}

        {/* Video and Playlist Section */}
        <div className={`${style.app}`}>
          <h2 className={style.videoTitle}>{videoTitle}</h2> 
          <div className='row'>
            <div className='col-lg-7 col-md-12'>
              <div 
                className={style.videoPlayer} 
                style={{ position: 'relative' }}
                onContextMenu={(e) => e.preventDefault()} 
              >
                {!currentVideo && (
                  <img 
                    src={data?.data?.data?.image} 
                    alt="Thumbnail"
                    style={{ width: '100%', height: 'auto', position: 'absolute', top: 0, left: 0 }}
                    className={`${style.thumb}`}
                  />
                )}
                {currentVideo ? (
                  <>
                    <ReactPlayer 
                      url={currentVideo} 
                      controls 
                      width="100%" 
                      height="fit-content"
                      config={{ 
                        file: {
                          attributes: {
                            controlsList: 'nodownload', 
                            disablePictureInPicture: true
                          }
                        }
                      }}
                      onPlay={() => setIsVideoPlaying(true)}
                      onPause={() => setIsVideoPlaying(false)}
                    />
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <Select
                        options={videoOptions}
                        onChange={handleVideoQualityChange}
                        value={videoOptions.find(option => option.value === currentVideo)}
                        classNamePrefix="react-select"
                        className="react-select-container"
                      />

                      <button className={`${style.purple} mb-3`} onClick={handleAttachmentsModalToggle}>
                        المرفقات
                      </button>
                    </div>

                    {showAttachmentsModal && (
                      <div className={style.modalOverlay} onClick={handleAttachmentsModalToggle}>
                        <div className={style.attachmentsModal} onClick={(e) => e.stopPropagation()}>
                          <h5 className="text-center">المرفقات</h5>
                          <button className="btn btn-danger mb-3" onClick={handleAttachmentsModalToggle}>إغلاق</button>
                          <div className={style.attachmentsContent}>
                            {attachments.length > 0 ? (
                              <div className="d-flex flex-wrap gap-3">
                                {attachments.map((attachment, index) => (
                                  <div key={index} className={style.attachmentItem}>
                                    <a href={attachment.url} target="_blank" className={style.attachmentLink}>
                                      <div className={`${style.attachmentContent}`}>
                                        <i className={`${style.attachmentIcon} fs-3 fa-solid fa-file-pdf text-dark`}></i>
                                        <div className={`${style.messi} d-flex justify-content-center align-items-center`}>
                                          <p className={`${style.attachmentTitle} text-dark`}>{attachment.name}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="text-center">لا توجد مرفقات</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-white'></div> 
                )}
              </div>
            </div>

            <div className='col-lg-5 col-md-12 mt-lg-0 mt-2 mt-sm-4'>
              <div className={`${style.playlist}`}>
                <h3>{data?.data?.data?.title}</h3>
                <p>منهج الدورة بالكامل</p>
                <div className={style.playlistItem}>
                  {data?.data?.data?.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className={`${style.playlist} mb-3`}>
                      <div 
                        className={`${style.item} ${section.slug === sectionSlug ? style.active : ''} d-flex justify-content-between align-items-center`}
                        onClick={() => { 
                          handleSectionClick(section.slug); 
                          setExpandedSection(sectionIndex === expandedSection ? null : sectionIndex);
                        }}
                      > 
                        <h3>{section.title}</h3>
                        <FontAwesomeIcon 
                          icon={expandedSection === sectionIndex ? faChevronUp : faChevronDown} 
                          className={`${style.toggleIcon}`} 
                        />
                      </div>
                      {expandedSection === sectionIndex && (
                        <div className={`${style.playlistItem} h-auto`}>
                          {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            sectionDatum?.data?.map((lecture, index) => (
                              <div
                                key={lecture.slug}
                                className={`${style.item} ${(index === activeIndex && section.slug === sectionSlug && lecture.slug === selectedLectureSlug) ? style.active : ''} d-flex justify-content-between align-items-center border-0`}
                                onClick={() => handleVideoClick(index, section.slug, lecture.slug, lecture.title)}
                              >
                                <div className="d-flex gap-1 align-items-center">
                                  {!data?.data?.data?.is_enrolled && (
                                    <img src={Lock} alt="قفل" />
                                  )}
                                  <h4 className='me-2'>{lecture.title}</h4>
                                </div>
                                <h5>{lecture.hours}:{lecture.minutes}:{lecture.seconds}</h5>
                              </div>
                            ))
                          )}
                        </div>                   
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Course Information Section */}
        <div className={`${style.information} my-3`}>
          <div className='row'>
            <div className='col-lg-7 col-md-12 mb-4'>
              <div className={`${style.left}`}>
                <div className={`${style.header}`}>
                  <h2>معلومات عن الدورة</h2>
                </div>
                <div className={`${style.body} pt-1`}>
                  <div
                    className={`${style.description} text-white`}
                    dangerouslySetInnerHTML={{ __html: data?.data?.data?.description }}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className={`${style.right}`}>
                <h2>المحاضر</h2>
                <h3>{data?.data?.data?.instructor?.name}</h3>
                <p>{data?.data?.data?.instructor?.description}</p>
                <h2 className="pt-2">البرامج في الباقة</h2>
                {data?.data?.data?.programs.map((item) => (
                  <div key={item.slug} className={`${style.programs} d-flex align-items-center gap-1`}>
                    <img src={item.icon} alt={item.name} />
                    <div>
                      <p>{item.name}</p>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster /> {/* Ensure Toaster is included for toast notifications */}
    </>
  );  
};

export default Courses;
